.TaskBar {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #141414;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.25), inset 2px 0px 0px #5e5e5e,
    inset 0px 2px 0px #5e5e5e, inset -3px 0px 0px #000000,
    inset 0px -3px 0px #000000;
  padding: 6px 8px;
  z-index: 15;

  & > div {
    display: flex;
    align-items: center;
  }

  button {
    font-weight: bold;
  }
}

.Icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.Divider {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;

  & > div {
    background: #262525;
    width: 1px;
    height: 26px;

    &:first-child {
      background: #3d3c3c;
    }
  }
}
