.SpotifyEmpty {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 72px 16px 16px 16px;
  text-align: center;
  background-color: #ffffff;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    margin: 24px 0px 16px 0px;
  }

  .ErrorMessage {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ff0000;
    margin-top: 16px;
  }

  .Actions {
    text-align: right;
  }
}

.SpotifyPlayer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .Content {
    padding: 16px;
    width: 100%;
    height: 100%;
  }

  .ResetMenu {
    background: #ffffff;
    border-bottom: 1px solid #000000;
  }

  .Marquee {
    height: 24px;
    width: 100%;
    background: #262223;
    display: flex;
    align-items: center;
  }

  .Visualizer {
    width: 100%;
    height: 64px;
    margin: 16px 0px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .PlayerContainer {
    height: 232px;
    width: 100%;
  }
}
