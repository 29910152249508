.Share {
  padding: 16px;
  background-color: #ffffff;
  height: 100%;

  .Header {
    font-weight: bold;
    margin-bottom: 16px;
  }

  .ShareLinkRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    button {
      width: 160px;
    }
  }

  .PersonalizeRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;

    input[type='text'] {
      width: 215px;
      margin-left: 8px;
    }
  }

  .Divider {
    border-bottom: 1px solid black;
    margin: 16px 0;
  }

  .Actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  }

  .Error {
    text-align: right;
    color: red;
  }
}
