.DesktopIcons {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin: 10px;
  z-index: 2;

  .DesktopIcon {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 136px;
    height: 128px;
    margin: 5px;
    cursor: pointer;

    & > * {
      margin: auto;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
