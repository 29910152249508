.VibeExplorer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #ffffff;

  .EditButton {
    text-decoration: underline;
  }

  .ImagePreview {
    width: 184px;
    height: 138px;
    margin: auto;
    margin-bottom: 16px;
    overflow: hidden;
    border: 3px solid #000000;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .FileUploader {
    text-align: center;

    input {
      visibility: hidden;
      position: absolute;
    }
  }

  .Actions {
    display: flex;
    justify-content: space-between;
  }

  .VibeList {
    width: 100%;
    margin: auto;
    margin-top: 18px;
    margin-bottom: 24px;

    .ListBody {
      border: 1px solid #000000;
      height: 145px;
      overflow: auto;

      .ListRow {
        cursor: pointer;
      }
    }

    .ListRow {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 6px 8px;
      color: #000000;

      input[type='text'] {
        width: 100%;
      }

      &.selected {
        background-color: #0000ff;
        color: #ffffff;
      }

      & > *:last-child {
        width: 25%;
        text-align: right;
        font-size: 12px;
      }

      & > *:first-child {
        width: 75%;
        display: flex;
        align-items: center;

        img {
          width: 23px;
          height: 23px;
          margin-right: 12px;
        }

        &.editing {
          min-width: 100%;
        }
      }

      &.ListHeader {
        padding: 5px 8px;
        background-color: #cccccc;
        border: 1px solid #000000;
        border-bottom: none;

        & > * {
          font-size: 14px;
        }

        &.clearBackground {
          background-color: #ffffff;
        }

        :global(.menu) {
          border-radius: 0;

          :global(.item) {
            background-color: #ffffff;

            &:hover {
              background-color: #000000;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
