.Window {
  border-radius: 0 !important;
  border: 3px solid #000000;
  width: 100%;
  padding: 3px;

  .CloseIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 0px !important;
  }

  .InnerContainer {
    background: #ffffff;
    border: 1px solid #000000;
  }

  .Title {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 2px 2px 4px;
    justify-content: space-between;
    background: linear-gradient(
        90deg,
        #0b0b29 2.05%,
        #292a38 51.03%,
        #5c5c5c 100%
      ),
      linear-gradient(90deg, #0b0b29 2.05%, #292a38 51.03%, #5c5c5c 100%);
    height: 28px;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.ViewOnlyErrorModal {
  width: 465px !important;
  border-radius: 0 !important;

  .Message {
    display: flex;
    column-gap: 24px;

    img {
      width: 64px;
      height: 64px;
    }
  }

  .Actions {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }

  .Content {
    padding: 16px;
  }
}

.DeleteMixModal {
  width: 349px !important;
  border-radius: 0 !important;

  .Message {
    display: flex;
    column-gap: 24px;

    img {
      width: 64px;
      height: 64px;
    }
  }

  .Actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: 32px;
  }

  .Content {
    padding: 16px;
  }
}

.CreateMixModal {
  width: 262px !important;
  border-radius: 0 !important;

  .CompositeTitle {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .Actions {
    display: flex;
    justify-content: space-between;
  }

  .PreviewContainer {
    width: 100%;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 3px solid #000000;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
    }

    & > div {
      z-index: 1;
    }
  }

  .Field {
    margin-bottom: 16px;
  }

  form {
    padding: 16px;

    label {
      line-height: 18px;
      margin-bottom: 4px;
      display: block;
      font-size: 14px;
    }
  }
}
