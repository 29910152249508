.Welcome {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 84px 72px;
  width: 867px;
  height: 524px;
  row-gap: 70px;
  max-width: 90%;
  max-height: 90%;
  justify-content: center;
  align-items: center;

  img {
    width: 452px;
    height: 205px;
    max-width: 90%;
    max-height: 90%;
  }

  button {
    width: 100%;
    font-size: 14px;
    height: 51px;
    font-weight: bold;
  }

  .Background {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .MobileMessage {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
  }
}
