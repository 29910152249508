.Graphic {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;

  .AddImage {
    border-bottom: 1px solid #000000;
  }

  .GiphySearchBar {
    padding: 8px;
    height: 40px;

    :global(input) {
      height: 24px;
      background: #eeeeee;
      border: 2px solid #000000;
      box-shadow: inset 3px 3px 0px rgb(0 0 0 / 25%);
      border-radius: 2px;
    }

    // to hide search button, no class available
    :global(div) {
      display: none;
    }
  }

  .GiphyGrid {
    // height of add new image, search input and upload image
    height: calc(100% - (27px + 40px + 44px));
    background-color: black;
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .GiphyAttribution {
    position: absolute;
    bottom: 44px; // height of Upload image bottom bar
    right: 0;
  }

  .UploadImageContainer {
    height: 44px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .UploadImageButton {
    cursor: pointer;
    display: inline-block;
    padding-left: 8px;
  }
}
