.YoutubePlayer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #ffffff;

  .AddressBar {
    background: #ffffff;
    align-items: center;
    display: flex;
    padding: 4px 8px;
    column-gap: 8px;

    div {
      width: 100%;
    }
  }

  .VideoContainer {
    position: relative;
    height: 100%;
    width: 100%;

    .Error {
      position: absolute;
      top: 0;
      left: 0;
      background: #ff0000;
      width: 100%;
      height: 28px;
      text-align: center;
      line-height: 28px;
      color: #ffffff;
      font-weight: bold;
    }
  }
}
