.About {
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  height: 100%;

  .ImageContainer {
    width: 429px;
    height: 208px;
    background-color: #cccccc;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;

    a {
      color: #000000;
      text-decoration: underline;
    }
  }
}
