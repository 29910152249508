.Canvas {
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.Background {
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.StartMenuContainer {
  width: 324px;
  position: absolute;
  z-index: 16;
  bottom: 42px;
  left: 0px;
  transition: left 0.3s;

  &.hidden {
    left: -333px;
  }
}

.ContextMenuContainer {
  width: 204px;
  position: absolute;
  z-index: 16;
  opacity: 1;
  transition: opacity 0.5s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.DraggableElement {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  border: 2px solid #000000;
  z-index: 3;

  &.selected {
    z-index: 10;
  }

  .FrameDecoration {
    position: absolute;
    background-color: #ffffff;
    background-image: url('/assets/dot-background.png');
    background-repeat: repeat;
    background-position: 0;
    z-index: 1;

    &.left {
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
    }

    &.top {
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
    }

    &.bottom {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
    }

    &.right {
      top: 0;
      right: 0;
      width: 4px;
      height: 100%;
    }
  }

  .DraggableFrame {
    margin: 4px;
    border: 1px solid #000000;
    background-color: transparent;
    position: relative;
  }

  .Background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
  }

  .MenuBar {
    height: 24px;
    padding: 0px 3px;
    background: linear-gradient(
        90deg,
        #0b0b29 2.05%,
        #292a38 51.03%,
        #5c5c5c 100%
      ),
      linear-gradient(90deg, #0b0b29 2.05%, #292a38 51.03%, #5c5c5c 100%);
    cursor: grab;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;

    .CloseIcon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    & > div {
      display: flex;
      align-items: center;

      img {
        margin-right: 9px;
      }
    }
  }

  .ResizeHandle {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 16px;
    font-weight: bold;
    cursor: se-resize;
  }

  .ElementContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}
