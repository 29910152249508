.Menu {
  background-color: #ffffff;
  border: 1px solid #000000;
  box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.4);
  font-size: 14px;

  img {
    width: 35px;
    height: 32px;
    margin-right: 12px;
  }

  div {
    display: flex;
    align-items: center;
  }
}

.Row {
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }

  label {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.Title {
  background: #cccccc;
  border-bottom: 1px solid #000000;
  font-weight: bold;
  padding: 4px 8px;
}

.MenuDivider {
  height: 1px;
  width: 100%;
  background: #000000;
  margin: 3px 0px;
}
