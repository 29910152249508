@font-face {
  font-family: 'IBM Plex Mono';
  src: url('/fonts/IBMPlexMono-Regular.otf') format('opentype');
}

body {
  font-family: 'IBM Plex Mono';
}

button,
.button,
input[type='submit'],
input[type='button'] {
  font-family: 'IBM Plex Mono';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #151515;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.35), inset 2px 0px 0px #5e5e5e,
    inset 0px 2px 0px #5e5e5e, inset -2px 0px 0px #000000,
    inset 0px -2px 0px #000000;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 12px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }

  &:disabled,
  &.disabled {
    background: #686868;
    color: rgba(255, 255, 255, 0.4);
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.35), inset 2px 0px 0px #7c7c7c,
      inset 0px 2px 0px #7c7c7c, inset -2px 0px 0px #505050,
      inset 0px -2px 0px #505050;
    cursor: default;
  }

  &.transparent {
    background: transparent;
    border: 0;
    color: #000000;
    box-shadow: none;
  }
}

input {
  font-family: 'IBM Plex Mono';
}

input[type='text'] {
  width: 100%;
  padding: 4px 8px;
  line-height: 18px;
  font-size: 14px;
  background: #eeeeee;
  border: 2px solid #000000;
  box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

input[type='file'] {
  visibility: hidden;
  position: absolute;
}
